var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{attrs:{"id":"sidebar-article"}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticClass:"img-fluid",staticStyle:{"height":"65px"},attrs:{"src":_vm.$store.state.logoImageUrl,"alt":""}})]),_vm._m(0)]),_c('div',{staticClass:"sidebar-links"},[_c('ul',{},[_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/home"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Home' }},[_c('span',[_c('i',{staticClass:"fas fa-home"}),_vm._v(" Home")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/teams"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Teams' || _vm.$route.name == 'TeamDetails',
              }},[_c('span',[_c('i',{staticClass:"fas fa-users"}),_vm._v(" Our Teams")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/gallery-categories"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'GalleryCategories' ||
                  _vm.$route.name == 'GalleryCategoryItems',
              }},[_c('span',[_c('i',{staticClass:"el-icon-files"}),_vm._v(" Gallery")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/intro-banners"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'IntroBanners' }},[_c('span',[_c('i',{staticClass:"el-icon-menu"}),_vm._v(" Intro Banners")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/programmes"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Programmes' }},[_c('span',[_c('i',{staticClass:"fas fa-list-alt"}),_vm._v(" Programmes")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/online-applications"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'OnlineApplications' }},[_c('span',[_c('i',{staticClass:"el-icon-menu"}),_vm._v("Online Applications")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/payments"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Payments' }},[_c('span',[_c('i',{staticClass:"fas fa-refresh"}),_vm._v(" Payments")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":'/dashboard/online-quiz/questions/root'}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'OnlineQuiz' ||
                  _vm.$route.name == 'OnlineQuizAnswer',
              }},[_c('span',[_c('i',{staticClass:"el-icon-s-claim"}),_vm._v("Online Quiz")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":'/dashboard/contact-us-messages'}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'ContactUsMessages' }},[_c('span',[_c('i',{staticClass:"el-icon-message"}),_vm._v("Contact Us Messages")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":'/dashboard/news-and-blogs'}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'NewsAndBlogs' }},[_c('span',[_c('i',{staticClass:"el-icon-document"}),_vm._v("News & Blogs")])])])],1),_c('li',{staticClass:"show_list",on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":'/dashboard/twitter-posts'}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'TwitterPosts' }},[_c('span',[_c('img',{attrs:{"src":require("@/assets/bxl-twitter.svg")}}),_vm._v(" Twitter Posts")])])])],1)])]),_c('div',{},[_c('ul',{},[_c('li',{on:{"click":function($event){return _vm.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/settings"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Settings' }},[_c('span',[_c('i',{staticClass:"fas el-icon-setting"}),_vm._v(" Settings")])])])],1),_c('li',{on:{"click":_vm.onLogoutIntent}},[_vm._m(1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"40px","font-size":"0.9em","font-weight":"500","color":"rgba(0, 0, 0, 0.5)"}},[_c('hr'),_c('span',[_vm._v(" Website Admin")]),_c('hr')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-link"},[_c('span',[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v(" Logout")])])
}]

export { render, staticRenderFns }