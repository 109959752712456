import Vue from "vue";
import Vuex from "vuex";
import { elearningStore } from "./elearningStore";
import {
  INITIALIZE_STORE,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_ACCESS_TOKEN,
  SHOW_PERMISSION_ERROR,
  SET_UNREAD_MESSAGES_COUNT,
  SET_REDIRECT_URL,
  SET_IS_AUTHENTICATING,
} from "./mutation-types";
import jwt_decode from "jwt-decode";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: null as string | null,
    deviceToken: "",
    unReadMessagesCount: "",
    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/cover_photo.jpg"),
    userRoles: [],
    // userRoles: ['VC'],
    firstName: "",
    isPesamoniUser: false,
    permissionError: null,
    redirectUrl: null as string | null,
    isAuthenticating: true,
  },

  mutations: {
    [SET_UNREAD_MESSAGES_COUNT]: (state, payload) => {
      state.unReadMessagesCount = payload;
    },
    [SHOW_PERMISSION_ERROR]: (state) => {
      //@ts-ignore
      state.permissionError = +new Date();
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save the Device Token as we need it for Single User Sessions
      state.deviceToken = fbDeviceToken;

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
      } catch (error) {
        //Nothing to do
      }

      localStorage.setItem("a-dt", fbDeviceToken);
    },
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore
      } catch (error) {
        //Nothing to do
      }
    },
    [LOGOUT_USER]: (state) => {
      //Save The Access Token in local Storage
      state.userId = "";
      state.accessToken = "";
      state.userInitials = "";
      state.profilePicture = "";
      state.firstName = "";
      state.userRoles = [];

      localStorage.removeItem("a-dt");
    },
    [INITIALIZE_STORE]: (state) => {
      state.deviceToken = localStorage.getItem("a-dt") || "";
    },
    [SET_REDIRECT_URL]: (state, payload) => {
      state.redirectUrl = payload;
    },
    [SET_IS_AUTHENTICATING]: (state, payload) => {
      state.isAuthenticating = payload;
    },
  },

  getters: {
    isLoggedIn: (state) => {
      return !!state.userId;
    },
  },

  plugins: [elearningStore],
  actions: {},
  modules: {},
});
