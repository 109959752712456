/* eslint-disable  no-unused-vars*/
import Vue from "vue";
import store from "../store";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/dashboard.vue";
import EnterEmailVerificationCode from "../views/enter-email-verification-code.vue";
import EnterTOTPVerificationCode from "../views/enter-totp-verification-code.vue";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/dashboard/home",
    meta: { requiresLogin: true },
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },

  {
    path: "/enter-email-verification-code",
    name: "EnterEmailVerificationCode",
    component: EnterEmailVerificationCode,
  },

  {
    path: "/enter-totp-verification-code",
    name: "EnterTOTPVerificationCode",
    component: EnterTOTPVerificationCode,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: "home",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/home.vue"),
      },
      {
        path: "teams",
        name: "Teams",
        component: () =>
          import(/* webpackChunkName: "teams." */ "../views/teams.vue"),
      },
      {
        path: "teams/:teamId",
        name: "TeamDetails",
        component: () =>
          import(/* webpackChunkName: "teams." */ "../views/team-details.vue"),
      },
      {
        path: "gallery-categories",
        name: "GalleryCategories",
        component: () =>
          import(
            /* webpackChunkName: "gallery-categories" */ "../views/gallery-categories.vue"
          ),
      },
      {
        path: "gallery-category-items/:galleryCategorySectionId",
        name: "GalleryCategoryItems",
        component: () =>
          import(
            /* webpackChunkName: "gallery-category-items" */ "../views/gallery-category-items.vue"
          ),
      },
      {
        path: "gallery-category-sections/:galleryCategoryId",
        name: "GalleryCategorySections",
        component: () =>
          import(
            /* webpackChunkName: "gallery-category-sections" */ "../views/gallery-category-sections.vue"
          ),
      },
      {
        path: "intro-banners",
        name: "IntroBanners",
        component: () =>
          import(
            /* webpackChunkName: "intro-banners" */ "../views/intro-banners.vue"
          ),
      },
      {
        path: "online-applications",
        name: "OnlineApplications",
        component: () =>
          import(
            /* webpackChunkName: "online-applications" */ "../views/online-applications.vue"
          ),
      },
      {
        path: "online-applications/edit-online-application/:onlineApplicationId",
        name: "EditOnlineApplication",
        component: () =>
          import(
            /* webpackChunkName: "edit-online-application." */ "../views/edit-online-application.vue"
          ),
      },
      {
        path: "settings",
        name: "Settings",
        component: () =>
          import(/* webpackChunkName: "settings" */ "../views/settings.vue"),
      },
      {
        path: "user-profile",
        name: "UserProfile",
        component: () =>
          import(
            /* webpackChunkName: "user-profile" */ "../views/user-profile.vue"
          ),
      },
      {
        path: "programme-details/:programmeId",
        name: "ProgrammeDetails",
        component: () =>
          import(
            /* webpackChunkName: "programme-details" */ "../views/programme-details.vue"
          ),
      },
      {
        path: "programmes",
        name: "Programmes",
        component: () =>
          import(
            /* webpackChunkName: "programme-details" */ "../views/programmes.vue"
          ),
      },
      {
        path: "online-quiz/questions/:answer_id",
        name: "OnlineQuiz",
        component: () =>
          import(
            /* webpackChunkName: "online-quiz" */ "../views/online-quiz.vue"
          ),
      },
      {
        path: "news-and-blogs",
        name: "NewsAndBlogs",
        component: () =>
          import(
            /* webpackChunkName: "news-and-blogs" */ "../views/news-and-blogs.vue"
          ),
      },
      {
        path: "news-and-blogs/:websiteBlogId",
        name: "NewsContent",
        component: () =>
          import(
            /* webpackChunkName: "news-content" */ "../views/news-content.vue"
          ),
      },
      {
        path: "payments",
        name: "Payments",
        component: () =>
          import(
            /* webpackChunkName: "transactions" */ "../views/payments.vue"
          ),
      },
      {
        path: "contact-us-messages",
        name: "ContactUsMessages",
        component: () =>
          import(
            /* webpackChunkName: "contact-us-messages" */ "../views/contact-us-messages.vue"
          ),
      },
      {
        path: "twitter-posts",
        name: "TwitterPosts",
        component: () =>
          import(
            /* webpackChunkName: "twitter-posts" */ "../views/twitter-posts.vue"
          ),
      },
      //In case the route is anything else
      {
        path: "*",
        redirect: "/dashboard/home",
      },
    ],
  },
  //In case the route is anything else
  {
    path: "*",
    redirect: "/dashboard/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name === "Login" && store.getters.isLoggedIn) {
    next({ path: "/dashboard/home" });
  }

  next();
  //Check if login is required for Routes with meta data loginRequired!
  // if (to.matched.some((record) => record.meta.requiresLogin)) {
  //   if (!store.getters.isLoggedIn) {
  //     //Redirect to Login Page
  //     next({ path: "/login" });
  //   } else next();
  // } else next();
});

export default router;
