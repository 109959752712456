<template>
  <section>
    <div id="sidebar-article">
      <div style="cursor: pointer">
        <div
          style="
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img
            :src="$store.state.logoImageUrl"
            style="height: 65px"
            alt=""
            class="img-fluid"
          />
        </div>
        <div
          style="
            margin-top: 40px;
            font-size: 0.9em;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.5);
          "
        >
          <hr />
          <span> Website Admin</span>
          <hr />
        </div>
      </div>

      <div class="sidebar-links" style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/home">
              <div class="side-link" :class="{ active: $route.name == 'Home' }">
                <span><i class="fas fa-home"></i> Home</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/teams">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'Teams' || $route.name == 'TeamDetails',
                }"
              >
                <span><i class="fas fa-users"></i> Our Teams</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/gallery-categories">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'GalleryCategories' ||
                    $route.name == 'GalleryCategoryItems',
                }"
              >
                <span><i class="el-icon-files"></i> Gallery</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/intro-banners">
              <div
                class="side-link"
                :class="{ active: $route.name == 'IntroBanners' }"
              >
                <span><i class="el-icon-menu"></i> Intro Banners</span>
              </div>
            </router-link>
          </li>
          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/programmes">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Programmes' }"
              >
                <span><i class="fas fa-list-alt"></i> Programmes</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/online-applications">
              <div
                class="side-link"
                :class="{ active: $route.name == 'OnlineApplications' }"
              >
                <span><i class="el-icon-menu"></i>Online Applications</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link to="/dashboard/payments">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Payments' }"
              >
                <span><i class="fas fa-refresh"></i> Payments</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link :to="'/dashboard/online-quiz/questions/root'">
              <div
                class="side-link"
                :class="{
                  active:
                    $route.name == 'OnlineQuiz' ||
                    $route.name == 'OnlineQuizAnswer',
                }"
              >
                <span><i class="el-icon-s-claim"></i>Online Quiz</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link :to="'/dashboard/contact-us-messages'">
              <div
                class="side-link"
                :class="{ active: $route.name == 'ContactUsMessages' }"
              >
                <span><i class="el-icon-message"></i>Contact Us Messages</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link :to="'/dashboard/news-and-blogs'">
              <div
                class="side-link"
                :class="{ active: $route.name == 'NewsAndBlogs' }"
              >
                <span><i class="el-icon-document"></i>News & Blogs</span>
              </div>
            </router-link>
          </li>

          <li v-on:click="closeNav()" class="show_list">
            <router-link :to="'/dashboard/twitter-posts'">
              <div
                class="side-link"
                :class="{ active: $route.name == 'TwitterPosts' }"
              >
                <span>
                  <img src="@/assets/bxl-twitter.svg" />
                  Twitter Posts</span
                >
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <div style="">
        <ul style="">
          <li v-on:click="closeNav()">
            <router-link to="/dashboard/settings">
              <div
                class="side-link"
                :class="{ active: $route.name == 'Settings' }"
              >
                <span><i class="fas el-icon-setting"></i> Settings</span>
              </div>
            </router-link>
          </li>
          <li @click="onLogoutIntent">
            <div class="side-link">
              <span><i class="fas fa-sign-out-alt"></i> Logout</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      help_messages_count: "",
      user_requests_count: "",
    };
  },

  computed: {},

  mounted() {
    this.messages_count();
  },

  methods: {
    async messages_count() {
      try {
        this.loading = true;
        let request = await this.$http.get(
          `staff/count-help-messages-and-user-requests/${this.$store.state.userId}`
        );
        //   console.log(request);
        if (
          request.data.success &&
          request.data.message == "COUNTS_RETRIEVED_SUCCESSFULLY"
        ) {
          this.help_messages_count = request.data.help_messages;
          this.user_requests_count = request.data.user_requests;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.showFailedMessage(
          "Unable to load Program categories",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.loading = false;
      }
    },

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    async onLogoutIntent() {
      this.closeNav();
      try {
        let logOut = await this.$confirm(
          "You will be immediately logged out of your account, Continue?",
          "Confirm Log out",
          {
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );

        if (logOut) {
          this.logOut();
        }
      } catch {
        //no-empty
      }
    },

    async logOut() {
      let request = await this.httpRequest({
        url: "/staff/logout",
        method: "POST",
        showFullScreenLoader: true,
        fullScreenLoaderMessage: "Logging you out...",
      });
      if (
        request &&
        request.success &&
        request.message == "LOGOUT_SUCCESSFUL"
      ) {
        this.$store.commit("LOGOUT_USER");
        this.$router.replace({ path: "/login" });
      }
    },
  },
};
</script>

<style scoped>
.sidebar-links {
  margin: 50px 0px;
  padding: 0px;
}
ul {
  padding: 0px;
}
ul li {
  list-style: none;
  text-align: left;
  font-weight: 400;
}
ul li i {
  margin-right: 5px;
}
.side-link {
  color: rgba(0, 0, 0, 0.74);
  font-weight: 500;
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}
.side-link.active {
  border-left: 6px solid var(--vu-red);
  color: var(--el-app-primary) !important;
  font-weight: 600;
}
.side-link:hover {
  background-color: #eeeeee;
  padding: 10px 40px;
  transition: 0.5s;
  color: #74a4c5;
}
.side-link:hover svg {
  color: #74a4c5;
}
.bg-white {
  background-color: white;
}
</style>
